import Navbar from '../Navbar/Navbar';
import './Header.scss';

/**
 * The header component generation.
 *
 * @param expanded indicates whether the header is expanded or not.
 * @return {JSX.Element} the header component.
 */
export default function Header({ expanded }: { expanded: boolean }): JSX.Element {
  return (
    <header className={`header ${expanded ? 'header-shrink' : ''}`} key="header">
      <Navbar />
    </header>
  )
}