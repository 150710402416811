import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Layout from '../components/Layout/Layout';
import Activities from './Activities';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/activities" element={<Activities />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
