import AOS from "aos";
import "aos/dist/aos.css";
import './../styles/pages/Activities.scss';
import { useEffect } from "react";
import Title from "../components/Title/Title";

export default function Home() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000
    });
  }, []);
  return (
    <>
      <div className='page activities'>
        <div className='activities__header'>
          <h1 className='activities__header-title'>Autour du Sept</h1>
        </div>
        <div className='activities__body'>
          <div className="about__body-section" data-aos="fade-up">
            <Title label="Les alentours" />
            <p className="about__body-presentation">
              Découvrez les lieux, activités et événements de la ville d'Alès et ses alentours.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}