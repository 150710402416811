import { useEffect, useState } from 'react';
import './Mosaic.scss';
import AOS from "aos";
import "aos/dist/aos.css";

type MosaicSize = 'default' | 'big' | 'wide' | 'tall';

export interface MosaicItem {
  url: string;
  size: MosaicSize;
}

export default function Mosaic({ items }: { items: Array<MosaicItem> }): JSX.Element {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const openModal = (index: number) => {
    setModalIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000
    });
  }, []);

  return (
    <div className="mosaic" >
      {items.map((item, index) => (
        <div data-aos="fade-up" className={`mosaic-container ${item.size}`}>
          <div key={index} className='mosaic-picture'>
            <img className='mosaic-picture-item' src={item.url} alt={`Image ${index}`} onClick={() => openModal(index)} />
          </div>
        </div>
      ))}
      {modalOpen && (
        <div className='mosaic__modal' onClick={closeModal}>
          <div className="mosaic__modal-content">
            <span className="mosaic__modal-close-button" onClick={closeModal}>
              &times;
            </span>
            <img className="mosaic__modal-content-picture" src={items[modalIndex].url} alt="Preview" />
          </div>
        </div>
      )}
    </div>
  );
}