import { TileLayer, Marker, Popup, MapContainer, LayerGroup } from 'react-leaflet';
import MarkerIcon from 'leaflet/dist/images/marker-icon.png';
import MarkerShadow from 'leaflet/dist/images/marker-shadow.png';
import './Map.scss';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';


export default function Map(): JSX.Element {

  const position: [number, number] = [44.1230256, 4.0805935];

  return (
    <div className='map'>
      <MapContainer className='map-container' {...{ center: position, zoom: 16, scrollWheelZoom: false }}>
        <TileLayer
          {...{ attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' }}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <LayerGroup>
          <Marker position={position} {...{
            icon: new Icon({
              iconUrl: MarkerIcon,
              iconRetinaUrl: MarkerIcon,
              iconSize: [25, 41],
              iconAnchor: [12.5, 41],
              shadowUrl: MarkerShadow,
              shadowSize: [41, 41]
            })
          }}>
            <Popup>
              <strong>Le Sept</strong><br />
              <span>21 Pl. Henri Barbusse, 30100 Alès</span>
            </Popup>
          </Marker>
        </LayerGroup>
      </MapContainer>
    </div>
  );
};


