import React, { useEffect, useState } from 'react';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import './Layout.scss';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const [headerExpanded, setHeaderExpanded] = useState(true);

  const handleHeaderHeight = () => {
    setHeaderExpanded(window.innerWidth <= 860 || window.scrollY !== 0);
  }

  useEffect(() => {
    handleHeaderHeight();
    const handleScroll = () => {
      handleHeaderHeight();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Header expanded={headerExpanded} />
      <main className={`layout ${headerExpanded ? 'layout-expanded' : 'layout-collapsed'}`}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;