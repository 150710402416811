import AOS from "aos";
import "aos/dist/aos.css";
import './../styles/pages/About.scss';
import Title from "../components/Title/Title";
import Map from "../components/Map/Map";
import { useEffect } from "react";
import { IoLocation, IoPersonCircle, IoBed, IoCarSharp } from "react-icons/io5";
import { FaBuilding, FaBath } from "react-icons/fa";
import { MdOutdoorGrill } from "react-icons/md";

export default function About() {

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000
    });
  }, []);

  return (
    <>
      <div className='page about'>
        <div className='about__header'>
          <h1 className='about__header-title'>À propos du Sept</h1>
        </div>
        <div className='about__body'>
          <div className="about__body-section" data-aos="fade-up">
            <Title label="Présentation" />
            <p className="about__body-presentation">
              Niché au cœur du centre-ville d'Alès, Le Sept offre un séjour paisible au sein même de l'animation de la ville en incarnant parfaitement l'équilibre entre tranquillité et activité. <br /><br />
              Situé en plein centre-ville, cet appartement tout fraîchement rénové est entouré de tout ce dont vous pourriez avoir besoin, des boutiques locales aux cafés alésiens en passant par les attractions culturelles. Son emplacement central en fait un lieu facilement accessible, vous permettant de vous plonger dans l'effervescence de la ville tout en retrouvant la quiétude dès le franchissement de la porte. <br /><br />
              Vivez une expérience de vie urbaine authentique où le charme rencontre la praticité, et où chaque jour apporte son lot de nouvelles aventures.
            </p>
            {/* <div className="about__body-button default-button">Voir les alentours</div> */}
          </div>
          <div className="about__body-section" data-aos="fade-up">
            <Title label="Caractéristiques" />
            <div className="about__body-caracteristics">

              <div className="about__body-caracteristics-panel">
                <span className="about__body-caracteristics-panel-title">L'appartement</span>
                <div className="about__body-caracteristics-panel-divider"></div>
                <div className="about__body-caracteristics-element">
                  <IoPersonCircle className="about__body-caracteristics-element-icon" />
                  <span>7  personnes maximum</span>
                </div>
                <div className="about__body-caracteristics-element">
                  <IoBed className="about__body-caracteristics-element-icon" />
                  <span>3  chambres</span>
                </div>
                <div className="about__body-caracteristics-element">
                  <FaBath className="about__body-caracteristics-element-icon" />
                  <span>2  salles de bain</span>
                </div>
                <div className="about__body-caracteristics-element">
                  <MdOutdoorGrill className="about__body-caracteristics-element-icon" />
                  <span>Avec terrasses</span>
                </div>
              </div>
              <div className="about__body-caracteristics-panel">
                <span className="about__body-caracteristics-panel-title">Les commodités</span>
                <div className="about__body-caracteristics-panel-divider"></div>
                <div className="about__body-caracteristics-element">
                  <FaBuilding className="about__body-caracteristics-element-icon" />
                  <ul>
                    <li>1er étage</li>
                    <li>En duplex</li>
                    <li>Tout équipé</li>
                  </ul>
                </div>
                <div className="about__body-caracteristics-element">
                  <IoCarSharp className="about__body-caracteristics-element-icon" />
                  <span>Parking à 300m</span>
                </div>
              </div>

            </div>
          </div>
          <div className="about__body-section" data-aos="fade-up">
            <Title label="Localisation" />
            <div className="about__body-location">
              <span className="about__body-location-label">
                <IoLocation className="about__body-location-icon" /> 21 Place Henri Barbusse, 30100 Alès
              </span>
              <Map />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
