import { useState } from "react";
import './Navbar.scss';
import { Link } from "react-router-dom";
import ContactButton from "../ContactButton/ContactButton";

/**
 * Navbar component used to navigate onto the app..
 *
 * @param expanded indicates whether the header that contains the navbar is on expanded state or not.
 * @return {JSX.Element} the navbar component.
 */
export default function Navbar(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/" onClick={closeMenu}>
          <img className="navbar-logo-picture" src="/assets/logo/logo.png" alt="LeSept logo picture" key="navbarLogo" />
        </Link>
      </div>
      <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
        <Link className='navbar-menu-item' to="/" onClick={closeMenu}>Accueil</Link>
        <Link className='navbar-menu-item' to="about" onClick={closeMenu}>À propos</Link>
        <ContactButton cssClass="navbar-menu-button" />
        {/* <Link className='navbar-menu-item' to="activities" onClick={closeMenu}>Les alentours</Link> */}
      </div>
      <button className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span className="hamburger-segment" />
        <span className="hamburger-segment" />
        <span className="hamburger-segment" />
      </button>
    </nav>
  );
}