import './Title.scss';

export default function Title({ label }: { label: string }): JSX.Element {
  return (
    <div className='title'>
      <span className="title-label">{label}</span>
      <div className="title-divider"></div>
    </div>

  );
}