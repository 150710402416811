import './Footer.scss';

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <span className="footer-copyright" key="footerCopyright">Copyright &copy; - {new Date().getFullYear()} - Tous droits réservés.</span>
      </div>
    </footer>
  );
};

export default Footer;