import { useEffect } from "react";
import ContactButton from "../components/ContactButton/ContactButton";
import Layout from "../components/Layout/Layout";
import AOS from "aos";
import "aos/dist/aos.css";
import './../styles/pages/Home.scss';
import './../styles/button.scss';
import Title from "../components/Title/Title";
import Mosaic from "../components/Mosaic/Mosaic";

export default function Home() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000
    });
  }, []);

  return (
    <>
      <div className='page home'>
        <div className='home__header'>
          <p className='home__header-title'>Appartement de charme pour une vie citadine sans compromis.</p>
          <div className='home__header-navigation'>
            <a href='/about' className='default-button'>À propos</a>
            <ContactButton />
          </div>
        </div>
        <div className='home__galery'>
          <Title label='Galerie' />
          <Mosaic items={[
            { url: '/assets/pictures/kitchen.png', size: 'big' },
            { url: '/assets/pictures/library.png', size: 'tall' },
            { url: '/assets/pictures/bedroom_main.png', size: 'tall' },
            { url: '/assets/pictures/living_room.png', size: 'default' },
            { url: '/assets/pictures/living_room_large.png', size: 'default' },
            { url: '/assets/pictures/bedroom_large.png', size: 'default' },
            { url: '/assets/pictures/patio.png', size: 'tall' },
            { url: '/assets/pictures/bedroom.png', size: 'default' },
            { url: '/assets/pictures/bathroom.png', size: 'tall' }
          ]} />
        </div>
      </div>
    </>
  );
}
