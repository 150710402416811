import { useState } from "react";
import './ContactButton.scss';

export default function ContactButton({ cssClass = 'default-button' }: { cssClass?: string }): JSX.Element {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className={cssClass} onClick={openModal}>Nous contacter</div>
      {modalOpen && (
        <div className='contact-button__modal' onClick={closeModal}>
          <div className="contact-button__modal-content">
            <h1 className="contact-button__modal-content-title">Contactez-nous !</h1>
            <p className="contact-button__modal-content-paragraph">Merci de l'intérêt que vous portez au Sept! <br /> Contactez-nous directement par mail ou téléphone pour réserver votre séjour de rêve</p>
            <div className="contact-button__modal-content-contact">
              <span className="contact-button__modal-content-contact-label">Téléphone : <strong>06.30.83.00.11</strong></span>
              <span className="contact-button__modal-content-contact-label">Email : <strong>pbessentiel@gmail.com</strong></span>
            </div>
            <div className="default-button" onClick={closeModal}>Fermer</div>
          </div>
        </div>
      )}
    </>
  )
}
